<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/pro_visaul6.jpg" alt="" />
      </template>
      <template v-slot:content>
        <h4>고정형 하이브리드 보철</h4>
        <img src="@/assets/images/pro_v_tit6_1.png" alt="" />
      </template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper class="magic4-concept-s1-bg">
      <template v-slot:header>
        <content-header title="<strong>Magic4 Concept</strong>"> </content-header>
      </template>
      <template v-slot:content>
        <card direction="row" :src="require('@/assets/images/img-magic-4-featire-img-01.png')">
          <ordered-list :items="items"> </ordered-list>
        </card>
      </template>
    </content-wrapper>
    <content-wrapper :order="1">
      <template v-slot:header>
        <content-header
          title="<strong>간편한 임플란트 수술 : </strong>분할된 T-bar 활용"
          subTitle="4~6개 임플란트 수술로 고정형 전악 보철물 제작"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <card direction="row" :src="require('@/assets/images/img-magic-4-b-01-img-01.png')">
          <ordered-list :items="items2"> </ordered-list>
        </card>
      </template>
    </content-wrapper>
    <content-wrapper :order="2">
      <template v-slot:header>
        <content-header
          title="<strong>고정형 지르코니아 보철</strong>"
          subTitle="틀니가 아닌 지르코니아 보철물로 환자 저작력 극적 회복"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <card direction="row" :src="require('@/assets/images/img-magic-4-b-02-img-01.png')">
          <ordered-list :items="items3"> </ordered-list>
        </card>
      </template>
    </content-wrapper>
    <content-wrapper :order="3">
      <template v-slot:header>
        <content-header
          title="<strong>쉽고 빠르고 정밀한 디지털 제작 과정</strong>"
          subTitle="수술 후 3회 내원만으로 완전 무치악 보철 제작 셋팅 완료"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <img src="@/assets/images/img-magic-4-b-03-img.png" alt="" />
      </template>
    </content-wrapper>
    <content-wrapper :order="4">
      <template v-slot:header>
        <content-header title="<strong>위생적인 유지관리</strong>" subTitle="T-bar Screw 분리로 보철 제거">
        </content-header>
      </template>
      <template v-slot:content>
        <div style="display: flex">
          <div>
            <img src="@/assets/images/img-magic-4-b-04-img-02.png" alt="" />
            <div class="discription-box">
              <p class="title1">치과의 유지 관리</p>
              <p class="title2">T-bar Screw 제거 후 유지 관리</p>
            </div>
          </div>
          <div style="margin: auto">
            <img src="@/assets/images/img-magic-4-b-04-img-01.png" alt="" />
            <div class="discription-box">
              <p class="title1">환자의 위생 관리</p>
              <p class="title2">구강세정기</p>
            </div>
          </div>
        </div>
      </template>
    </content-wrapper>
    <content-wrapper :order="5">
      <template v-slot:header>
        <content-header
          title="<strong>편리한 A/S시스템</strong>"
          subTitle="보철 파손, 분실 시, 동일한 장치물 재 제작 가능 (개인인증카드 발급)"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <img src="@/assets/images/img-magic-4-b-05-img-01.png" alt="" />
        <div class="benefit5">
          <div>개인인증카드로 데이터 관리</div>
          <div>저장된 데이터 불러오기</div>
          <div>보철 재 제작</div>
        </div>
      </template>
    </content-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import Card from '@/shared/Card.vue';
import OrderedList from '@/shared/OrderedList.vue';
import ContentHeader from '@/shared/ContentHeader.vue';

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    Card,
    OrderedList,
    ContentHeader
  },
  setup() {
    const items = ref([
      {
        backgroundColor: '#f5c867',
        content: `<p class="t1">
          <strong>4~6개 임플란트 식립</strong>
        </p>
        <p class="t2">
          <span>
            골질이 좋은 곳에 선별적으로 식립 가능
            <br />
            <span class="gbr">GBR 최소화</span>
          </span>
        </p>`
      },
      {
        backgroundColor: '#53b6bf',
        content: `<p class="t1">
          <strong>분할된 T-bar</strong>
        </p>
        <p class="t2">
          임플란트
          <span style="color: #03aace; font-weight: bold"
            >식립 각도를 보상</span
          >하는 분할된 Bar 형태의 맞춤형 Abutment
        </p>`
      },
      {
        backgroundColor: '#0172fe',
        content: `<p class="t1">
          <strong style="color: #0172fe">
            지르코니아 보철 (틀니X)
          </strong>
        </p>
        <p class="t2">
          <span style="color: #0172fe; font-weight: bold">
            기능성/심미성 극적 회복
          </span>
        </p>`
      },
      {
        backgroundColor: '#4559ae',
        content: `<p class="t1"><strong>T-bar Screw</strong></p>`
      }
    ]);

    const items2 = ref([
      {
        content: `<p class='t1'>최소 임플란트 수술</p>`
      },
      {
        content: `<p class='t1'>
          각도 제한 없는 간편한 수술
          <span style='font-size: 22px'>(맞춤형 T-bar제작)</span>
        </p>`
      },
      {
        content: `<p class='t1'>
          짧은 힐링 시간
          <span style='font-size: 22px'>(골질이 좋은 곳에 식립)</span>
        </p>`
      }
    ]);

    const items3 = ref([
      {
        content: `<p class="t1">70~80% 수준의 저작력 회복</p>`
      },
      {
        content: `<p class="t1">자연치아와 유사한 심미성</p>`
      },
      {
        content: `<p class="t1">
          구강 환경 개선
          <span style="font-size: 22px">(입천장 개방)</span>
        </p>`
      }
    ]);

    return { items, items2, items3 };
  }
});
</script>

<style scoped lang="scss">
@import './Magic4View.scss';
</style>
