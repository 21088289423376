
import { defineComponent, ref } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import Card from '@/shared/Card.vue';
import OrderedList from '@/shared/OrderedList.vue';
import ContentHeader from '@/shared/ContentHeader.vue';

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    Card,
    OrderedList,
    ContentHeader
  },
  setup() {
    const items = ref([
      {
        backgroundColor: '#f5c867',
        content: `<p class="t1">
          <strong>4~6개 임플란트 식립</strong>
        </p>
        <p class="t2">
          <span>
            골질이 좋은 곳에 선별적으로 식립 가능
            <br />
            <span class="gbr">GBR 최소화</span>
          </span>
        </p>`
      },
      {
        backgroundColor: '#53b6bf',
        content: `<p class="t1">
          <strong>분할된 T-bar</strong>
        </p>
        <p class="t2">
          임플란트
          <span style="color: #03aace; font-weight: bold"
            >식립 각도를 보상</span
          >하는 분할된 Bar 형태의 맞춤형 Abutment
        </p>`
      },
      {
        backgroundColor: '#0172fe',
        content: `<p class="t1">
          <strong style="color: #0172fe">
            지르코니아 보철 (틀니X)
          </strong>
        </p>
        <p class="t2">
          <span style="color: #0172fe; font-weight: bold">
            기능성/심미성 극적 회복
          </span>
        </p>`
      },
      {
        backgroundColor: '#4559ae',
        content: `<p class="t1"><strong>T-bar Screw</strong></p>`
      }
    ]);

    const items2 = ref([
      {
        content: `<p class='t1'>최소 임플란트 수술</p>`
      },
      {
        content: `<p class='t1'>
          각도 제한 없는 간편한 수술
          <span style='font-size: 22px'>(맞춤형 T-bar제작)</span>
        </p>`
      },
      {
        content: `<p class='t1'>
          짧은 힐링 시간
          <span style='font-size: 22px'>(골질이 좋은 곳에 식립)</span>
        </p>`
      }
    ]);

    const items3 = ref([
      {
        content: `<p class="t1">70~80% 수준의 저작력 회복</p>`
      },
      {
        content: `<p class="t1">자연치아와 유사한 심미성</p>`
      },
      {
        content: `<p class="t1">
          구강 환경 개선
          <span style="font-size: 22px">(입천장 개방)</span>
        </p>`
      }
    ]);

    return { items, items2, items3 };
  }
});
